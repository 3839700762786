<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline">Do you really want to DELETE this item?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false">No</v-btn>
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Edit Admission Form Tab Fields </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form" lazy-validation>
          <v-container fluid>
            <v-col cols="12" sm="12" v-for="(tab, index1) in formFields" :key="index1">
              <h1>TAB: {{ tab.name }}</h1>
              <v-row
                v-for="(item, index) in tab.fields"
                :key="index"
                cols="12"
                sm="12"
                style="border: 1px solid"
              >
                <v-text-field
                  v-model="item.sequence"
                  :rules="rules.sequence"
                  color="purple name-2"
                  label="sequence"
                  class="col-md-3"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="item.caption"
                  :rules="rules.caption"
                  color="purple name-2"
                  label="Caption"
                  class="col-md-3"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="item.label"
                  :rules="rules.label"
                  color="purple name-2"
                  label="Field Label"
                  class="col-md-3"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="item.length"
                  :rules="rules.length"
                  color="purple name-2"
                  label="length"
                  class="col-md-2"
                  required
                ></v-text-field>
                <v-radio-group v-model="item.editable" row class="col-md-3" required>
                  <v-radio label="Show" value="1"></v-radio>
                  <v-radio label="Hide" value="0"></v-radio>
                </v-radio-group>
                <v-radio-group v-model="item.frontend" row class="col-md-3" required>
                  <v-radio label="Optional " value="0"></v-radio>
                  <v-radio label="Mandatory" value="1"></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn :disabled="submitting" class="custom-submit-button" type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        Admission Form Tab Fields
        <div class="my-2 ml-2">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showForm"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search fields"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showEditForm(item)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="previewForm(item)"
          >
            <v-icon dark>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import AdmissionForm from "@/MainServices/AdmissionForm.js";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      formFields: [],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        sequence: [(val) => !!val || "Sequence is required"],
        caption: [(val) => !!val || "Caption is required"],
        label: [(val) => !!val || "label is required"],
        length: [(val) => !!val || "length is required"],
        frontend: [(val) => (val || "").length > 0 || "This Option is required"],
        editable: [(val) => (val || "").length > 0 || "This Option is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      valid: true,
      headers: [
        {
          text: "Form Name",
          align: "left",
          sortable: false,
          value: "name",
          width: "30%",
        },
        {
          text: "Form Tabs",
          align: "left",
          sortable: false,
          value: "tabs",
          width: "50%",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
      tabsData: [],
      types: [
        { value: "text", text: "text" },
        { value: "textarea", text: "textarea" },
        { value: "date", text: "date" },
        { value: "file", text: "file" },
        { value: "integer", text: "integer" },
        { value: "checkbox", text: "checkbox" },
        { value: "radiobutton", text: "radiobutton" },
        { value: "select", text: "select" },
        { value: "multiselect", text: "multiselect" },
      ],
    };
  },
  validations: {
    formFields: {
      required,
      $each: {
        caption: { minLength: minLength(1), required },
        label: { minLength: minLength(1), required },
        sequence: { minLength: minLength(1), required },
        length: { minLength: minLength(1), required },
        frontend: { minLength: minLength(1), required },
        editable: { minLength: minLength(1), required },
      },
    },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      AdmissionForm.getInstituteTemplates()
        .then((res) => {
          res.data.map((el) => {
            el.tabs = "";
            el.institute_form_tabs.map((el2) => {
              el.tabs !== ""
                ? (el.tabs = el.tabs + ", " + el2.caption)
                : (el.tabs = el2.caption);
            });
          });
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    previewForm(item) {
      localStorage.setItem("preview_form_id", item.id);
      let routeData = this.$router.resolve({ name: "PreviewAdmissionForm" });
      window.open(routeData.href, "_blank");
    },
    showEditForm(item) {
      item.institute_form_tabs.map((tabs) => {
        let store_local = {
          id: tabs.id,
          name: tabs.name,
          sequence: tabs.sequence,
          fields: [],
        };
        tabs.fields.map((el2) => {
          store_local.fields.push({
            id: el2.id,
            caption: el2.caption,
            label: el2.label,
            sequence: el2.sequence,
            length: el2.length,
            type: el2.type,
            frontend: el2.frontend,
            editable: el2.editable,
          });
        });
        this.formFields.push(store_local);
      });
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [
        {
          tab_id: null,
          caption: "",
          label: "",
          sequence: "",
          length: null,
          type: "",
          frontend: null,
          editable: null,
        },
      ];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },

    submit() {
      if (this.editId && this.$refs.form.validate()) {
        let fields = [];
        this.formFields.map((el) => {
          el.fields.map((el2) => {
            fields.push(el2);
          });
        });
        this.submitting = true;
        AdmissionForm.updateInstituteFormFields(fields)
          .then((res) => {
            this.showFormData = false;
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Fields Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      AdmissionForm.deleteField(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Field Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    AdmissionForm.getFormTabs()
      .then((res) => {
        res.data.map((el) => {
          this.tabsData.push({ value: el.id, text: el.name });
        });
      })
      .catch((e) => {});
  },
};
</script>

<style>
.v-input {
  max-width: 25% !important;
}
</style>
